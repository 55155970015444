import { Component, OnInit, Input, NgModule, Output, EventEmitter } from '@angular/core';
import { ApexSurveyQuestion } from '../constants/survey';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  selected = 'NA';
  response: string;
  @Input() question: ApexSurveyQuestion;
  @Output() answered = new EventEmitter();

  ngOnInit() {
    this.question.surveyAnswer = '';
  }
  setResponse(changeEvent) {
    if (changeEvent && this.question.surveyAnswer !== changeEvent.source.value) {
      this.question.surveyAnswer = changeEvent.source.value;
      changeEvent.value = this.question;
      this.answered.emit(changeEvent);
    }
  }
}
