import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { API_URL } from './constants/connections';
import { Survey, ApexSurveyQuestion } from './constants/survey';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataLayerService } from '../data-layer.service';
import { Observable, Observer } from 'rxjs';
// import { } from 'rxjs/add/operator/map';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.css']
})
export class SurveyFormComponent implements OnInit {
  @Output() submitted = new EventEmitter();
  surveyCode: string;
  survey: Survey;
  comments: string;
  questionList: Observable<ApexSurveyQuestion[]>;
  questionListStatic: ApexSurveyQuestion[];

  constructor(private route: ActivatedRoute, private svc: DataLayerService) {
    this.route.queryParams.subscribe(params => {
      this.surveyCode = params['code'];
      if (this.surveyCode != null && this.surveyCode.length > 0) {
        this.questionList = this.svc.getQuestions(this.surveyCode);
      }
    });
  }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.surveyCode = params['code']; // (+) converts string 'id' to a number
    });
    this.questionListStatic = [];
    this.comments = '';
  }
  setResponse(event) {
    const question = event.value;
    if (this.questionListStatic.findIndex(p => p.id === question.id) > -1) {
      // this.questionListStatic.find(p => p.id === question.id).surveyAnswer = question.surveyAnswer;
    } else { this.questionListStatic.push(question); }
  }
  setComments(event) {
    this.comments = event.srcElement.value;
  }
  submitSurvey() {
    this.survey = { comments: this.comments, responses: this.questionListStatic };
    this.svc.postSurvey(this.survey, this.surveyCode);
    this.submitted.emit();
  }
}
