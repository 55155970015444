import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API_URL, httpOptions } from './survey-form/constants/connections';
import { Survey, ApexSurveyQuestion } from './survey-form/constants/survey';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  constructor(private http: HttpClient) { }
  postSurvey(survey: Survey, code: string)  {
    this.http
      .post(API_URL + '/api/postwebsurvey/' + code, survey)
      .subscribe(catchError(this.handleError));
    // .subscribe(res => { catchError(this.handleError) });
  }
  getSurvey(code: string): Observable<Survey> {
    return this.http.get<Survey>(API_URL + '/api/getSurveyByCode/' + code)
      .pipe(catchError(this.handleError));
  }

  getQuestions(code: string): Observable<ApexSurveyQuestion[]> {
    const compareFn = (a, b) => {
      if (a.sortOrder < b.sortOrder) {
        return -1;
      }
      if (a.sortOrder > b.sortOrder) {
        return 1;
      }
      return 0;
    };
    return this.http.get<ApexSurveyQuestion[]>(API_URL + '/api/getdriversurveycodequestions/' + code)
      .pipe(map(results => results.sort(compareFn)), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError('An error occurred and we weren\'t able to retrieve your survey. Please contact Capstone helpdesk.');
  }
}

