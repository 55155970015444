import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-complete-form',
  templateUrl: './survey-complete-form.component.html',
  styleUrls: ['./survey-complete-form.component.css']
})
export class SurveyCompleteFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
