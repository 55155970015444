import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from './app.component';
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { DataLayerService } from './data-layer.service';
import { QuestionComponent } from './survey-form/question/question.component';
import { AppRoutingModule } from './app-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { SurveyCompleteFormComponent } from './survey-complete-form/survey-complete-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    SurveyFormComponent,
    QuestionComponent,
    SurveyCompleteFormComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [DataLayerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
